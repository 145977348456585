import { DemographicFilter } from "ts/filters/demographicFilter";
import { AnalysisModel } from "ts/filters/analysisModel";
import { AnalysisDetails, PreviewUser } from "ts/analysis";
import { PageErrorType } from "ts/enums/pageErrorType";
import { ApiTopicNode } from "ts/topic";
import { WidgetsApiData } from "ts/widget";
import {
  Category,
  CommentersCount,
  CommentsSearchType,
  ManualRedactionPermission,
  RoleType,
} from "@explorance/mly-types";
import { Range } from "ts/range";
import { ExcludedData } from "ts/overview";

export type AnalysisContextState = {
  analysisError: PageErrorType;
  analysisDetails: AnalysisDetails;
  commenterDetails: CommentersCount;
  emptyCommentsDetails: ExcludedData;
  analysisModels: AnalysisModel[];
  selectedModelId: number;
  selectedTopicNodes: ApiTopicNode[];
  lastSelectedModelId: number;
  lastSelectedTopicNodes: ApiTopicNode[];
  availableDemographicFilters: DemographicFilter[];
  selectedDemographicFilters: DemographicFilter[];
  threshold: number;
  alertThreshold: number;
  selectedAlertRange: Range;
  widgetsApiData: WidgetsApiData;
  commentStatistics: any;
  selectedTranslationLanguage: string;
  loadingPreviewUsers: boolean;
  loadingAnalysisDetails: boolean;
  loadingAnalysisModels: boolean;
  loadingAvailableDemographicFilters: boolean;
  loadingCommentStatistics: boolean;
  showWhiteBackground: boolean;
  selectedColumnFilters: string[];
  previewUser: PreviewUser;
  previewUsers: PreviewUser[];
  availableCategories: Category[];
  selectedCategories: string[];
  commentsSearchType: CommentsSearchType;
};

export const getInitialState = (analysisId: number): AnalysisContextState => ({
  analysisError: null,
  analysisDetails: {
    id: analysisId,
    name: null,
    graphId: null,
    env: null,
    precision: null,
    allColumns: [],
    selectedColumns: [],
    suggestedColumns: [],
    selectedCommentersColumn: null,
    excludedValues: [],
    totalExcluded: null,
    selectedVariableMappingId: null,
    selectedVariableMappingName: null,
    status: null,
    uploadStatus: null,
    lastUploadedFileId: null,
    maxSelectableColumns: null,
    availableResults: {
      alerts: null,
      topics: null,
      sentiments: null,
      recommendations: null,
    },
    sharing: {
      sharingId: null,
      allowExport: false,
      allowPin: false,
      permissionLevel: null,
      overviewAccess: null,
      topicExplorerAccess: null,
      manualRedactionPermission: null,
      commentExplorerAccess: null,
      dashboardAccess: null,
      summaryAccess: null,
    },
    selectedModel: {
      customModelId: null,
      customModelName: null,
      family: null,
      graphId: null,
      isAvailable: false,
      isCustom: false,
      isOutdated: false,
      isPowerset: false,
      languages: [],
    },
    suggestedModel: {
      family: null,
      graphId: null,
      languages: [],
      isPowerset: false,
    },
    availableVariableMappings: [],
    translationLanguages: [],
    availableLanguages: [],
  },
  commenterDetails: {
    commentersColumn: undefined,
    commentersCount: null,
  },
  emptyCommentsDetails: {
    totalColumns: null,
    totalEmptyCells: null,
    emptyCommentCells: [],
    excludedComments: [],
  },
  analysisModels: null,
  selectedModelId: null,
  selectedTopicNodes: [],
  lastSelectedModelId: null,
  lastSelectedTopicNodes: [],
  availableDemographicFilters: [],
  selectedDemographicFilters: [],
  threshold: null,
  alertThreshold: null,
  selectedAlertRange: null,
  widgetsApiData: null,
  commentStatistics: null,
  selectedTranslationLanguage: null,
  loadingPreviewUsers: true,
  loadingAnalysisDetails: true,
  loadingAnalysisModels: true,
  loadingAvailableDemographicFilters: true,
  loadingCommentStatistics: true,
  showWhiteBackground: false,
  selectedColumnFilters: [],
  previewUser: {
    firstname: null,
    lastname: null,
    id: null,
    allowExport: false,
    summaryAccess: null,
    overviewAccess: null,
    topicExplorerAccess: null,
    commentExplorerAccess: null,
    dashboardAccess: null,
    allowPin: false,
    manualRedactionPermission: ManualRedactionPermission.Denied,
    roleType: RoleType.Viewer,
  },
  previewUsers: [],
  availableCategories: [],
  selectedCategories: [],
  commentsSearchType: CommentsSearchType.Comments,
});
