import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSummaryOverview } from "services/analysis";
import { AppDispatch } from "store";
import { SummaryType } from "ts/summary";
import { setIsCachedResponse } from "./summarySlice";

type FetchSummaryOverviewParams = {
  analysisId: number;
  user_id?: number;
};

export const fetchSummaryOverview = createAsyncThunk<
  SummaryType,
  FetchSummaryOverviewParams,
  { dispatch: AppDispatch }
>("summary/fetchSummaryOverview", async ({ analysisId, user_id }, thunkAPI): Promise<any> => {
  try {
    const res = await getSummaryOverview(analysisId, user_id);

    if (res.headers["request-duration"] <= 2000) {
      thunkAPI.dispatch(setIsCachedResponse(true));
    }

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
