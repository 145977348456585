import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { AnalysisContext } from "context/AnalysisContext";
import { SHARING_PREVIEW_HEADER_HEIGHT } from "assets/constants/sharingPreviewHeaderHeight";
import { routes } from "routes";

import { useAppSelector } from "store";
import { useQueryParams } from "hooks/useQueryParams";
import useClickOutside from "hooks/useClickOutside";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { getUserFullName } from "utils/getUserFullName";
import {
  resetPreviewUser,
  setLoadingAvailableDemographicFilters,
  setPreviewUser,
  setPreviewUsers,
  setSelectedModelId,
} from "context/AnalysisContext/actions";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { DropdownMenu } from "components/DropdownMenu";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { ButtonVariant } from "ts/enums/button";
import { DropdownSize } from "ts/dropdown";
import { getDefaultAnalysisPage } from "utils/getDefaultAnalysisPage";

export const SharingPreviewHeader = () => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const [state, dispatch] = useContext(AnalysisContext);

  const settingsState = useAppSelector((state) => state.settings);

  const history = useHistory();
  const { sharingPreview, step, sharingId, expandedRowId, sharedWithPage } = useQueryParams();

  const dropdownRef = useClickOutside(() => setDropdownOpen(false));

  const dropdownItems = state.previewUsers.map((user) => ({
    label: getUserFullName(user),
    value: user.id,
    isActive: state.previewUser.id === user.id,
  }));

  // functions
  const handleClosePreview = () => {
    dispatch(setLoadingAvailableDemographicFilters(true));
    dispatch(resetPreviewUser());

    if (
      state.analysisDetails.selectedModel?.customModelId &&
      state.analysisDetails.selectedModel?.customModelId !== state.selectedModelId
    ) {
      dispatch(setSelectedModelId(state.analysisDetails.selectedModel.customModelId));
    }

    history.push(
      routes.sharingPage(state.analysisDetails.id) +
        getUrlQueryString({ view: sharingPreview, step, expandedRowId, sharedWithPage })
    );
  };

  const handleSelectUser = async (id: number) => {
    const previewUser = state.previewUsers.find((user) => user.id === id);
    dispatch(setPreviewUser(previewUser));
    const queryParams = getUrlQueryString({
      sharingPreview: sharingPreview,
      sharingId,
      expandedRowId,
      sharedWithPage,
      step,
    });

    history.push(
      await getDefaultAnalysisPage(
        state.analysisDetails.id,
        settingsState.features.llm,
        queryParams,
        previewUser
      )
    );
  };

  const getSelectedUserName = () => {
    if (state.previewUsers.length > 0 && !state.previewUser.id) {
      return getUserFullName(state.previewUsers[0]);
    }
    return dropdownItems.find((item) => item.value === state?.previewUser.id)?.label;
  };

  useEffect(() => {
    return () => {
      dispatch(setPreviewUsers([]));
      dispatch(resetPreviewUser());
    };
  }, [dispatch]);

  return (
    <StyledSharingPreviewHeader>
      <StyledLeftContainer>
        <Text resource="sharing.header.previewFor" />
        <StyledSelectUser onClick={() => setDropdownOpen((isOpen) => !isOpen)} ref={dropdownRef}>
          <StyledSelectedUser>{getSelectedUserName()}</StyledSelectedUser>
          <StyledSelectUserDropdownButton style={{ backgroundColor: dropdownOpen && Color.gray40 }}>
            <Icon
              type={IconType.dropdownArrow}
              size={8}
              color={Color.neutral30}
              style={{ cursor: "pointer" }}
            />
          </StyledSelectUserDropdownButton>
          <DropdownMenu
            items={dropdownItems}
            show={dropdownOpen}
            position={{ top: 34, left: 115 }}
            size={DropdownSize.lg}
            width="175px"
            maxHeight="336px"
            onChange={handleSelectUser}
          />
        </StyledSelectUser>
      </StyledLeftContainer>
      <Button
        variant={ButtonVariant.danger}
        style={{ height: SHARING_PREVIEW_HEADER_HEIGHT }}
        onClick={handleClosePreview}
      >
        <Icon
          type={IconType.xCircle}
          size={14}
          color={Color.neutral30}
          style={{ marginRight: "8px" }}
        />
        <Text resource="button.closePreview" />
      </Button>
    </StyledSharingPreviewHeader>
  );
};

const StyledSharingPreviewHeader = styled.div`
  background-color: ${Color.blue90};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${SHARING_PREVIEW_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLeftContainer = styled.div`
  display: flex;
  font-size: 14px;
  color: ${Color.neutral30};
  margin-left: 28px;
`;

const StyledSelectUserDropdownButton = styled.button`
  all: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
`;

const StyledSelectUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 186px;
`;

const StyledSelectedUser = styled.div`
  padding: 0 8px 0 10px;
  font-weight: bold;
`;
