import React, { useContext, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { AnalysisContext, AnalysisContextProvider } from "context/AnalysisContext";
import {
  resetAllFilters,
  setSelectedTranslationLanguage,
  showWhiteBackground,
} from "context/AnalysisContext/actions";

import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { setShowManageWidgetsModal } from "store/widgetOrdering/widgetOrderingModal";

import { routes } from "routes";
import { getAnalysisPageType, getLastWidgetPushDateArgs } from "./helpers";
import { SharingPreviewHeader } from "./SharingPreviewHeader";
import { pushWidgetChangesToSharees } from "services/analysis/sharing";
import { getSlug } from "utils/getSlug";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { getLocalTime } from "utils/getLocalTime";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";

import { ErrorScreen } from "components/ErrorScreen";
import { Notch } from "./Notch";
import { ExportButton } from "./ExportButton";
import { AnalysisSettingsButton } from "./AnalysisSettingsButton";
import { Tabs } from "components/Tabs";
import { ConditionalWrapper } from "components/ConditionalWrapper";
import { FileUploadingModal } from "components/_modals/FileUploadingModal";
import { AnalysisJobProcessingModal } from "components/_modals/JobProcessingModal";
import { LoadingDotsScreen } from "components/_screens/LoadingDotsScreen";
import { Button } from "components/_buttons/Button";
import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { Tooltip } from "components/Tooltip";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";

import { AnalysisPageType } from "ts/enums/analysisPageType";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Color } from "ts/enums/color";
import {
  AnalysisStatus,
  FileUploadStatus,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingTopicExplorerAccess,
  CategorizationType,
  PermissionLevel,
  RoleType,
  SharingOverviewAccess,
  StatisticsType,
  CommentsView,
  LanguageCode,
  SharingSummaryAccess,
} from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { SHARING_PREVIEW_HEADER_HEIGHT } from "assets/constants/sharingPreviewHeaderHeight";
import { SelectOption } from "ts/select";
import { PageErrorType } from "ts/enums/pageErrorType";

const AnalysisPage = ({ children }) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const settingsState = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const [state, analysisContextDispatch, handlers] = useContext(AnalysisContext);
  const [activeTab, setActiveTab] = useState<AnalysisPageType>();
  const [showModelNameTooltip, setShowModelNameTooltip] = useState<boolean>(false);

  const translationOptions: SelectOption[] = [
    {
      label: <Text resource="comments.multilingual.dropdown.original" />,
      value: null,
    },
    ...(state.analysisDetails?.availableLanguages?.map((languageCode) => ({
      label: <Text resource={`comments.multilingual.dropdown.[${languageCode}]`} />,
      value: LanguageCode[languageCode],
    })) || []),
  ];

  const location = useLocation();
  const { getResource } = useResource();
  const { sharingPreview, step, expandedRowId, sharedWithPage, sharingId } = useQueryParams();

  //Sharing access variables
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isAnalysisOwner = !state.analysisDetails?.sharing;
  const doesPreviewUserExist = state.previewUser?.id !== null;
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const hasContributionPermission =
    state.analysisDetails?.sharing?.permissionLevel === PermissionLevel.Contribution && !isViewer;

  const hasSummaryAccess = doesPreviewUserExist
    ? state.previewUser?.summaryAccess === SharingSummaryAccess.Shared
    : state.analysisDetails?.sharing?.summaryAccess === SharingSummaryAccess.Shared;

  const hasOverviewAccess = doesPreviewUserExist
    ? state.previewUser?.overviewAccess === SharingOverviewAccess.Shared
    : state.analysisDetails?.sharing?.overviewAccess === SharingOverviewAccess.Shared;

  const hasDashboardAccess = doesPreviewUserExist
    ? state.previewUser?.dashboardAccess === SharingDashboardAccess.Shared
    : state.analysisDetails?.sharing?.dashboardAccess === SharingDashboardAccess.Shared;

  const hasTopicsAccess = doesPreviewUserExist
    ? state.previewUser?.topicExplorerAccess === SharingTopicExplorerAccess.Shared
    : state.analysisDetails?.sharing?.topicExplorerAccess === SharingTopicExplorerAccess.Shared;

  const hasCommentsAccess = doesPreviewUserExist
    ? state.previewUser?.commentExplorerAccess === SharingCommentExplorerAccess.Shared
    : state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;

  const allowExport = doesPreviewUserExist
    ? state.previewUser?.allowExport
    : state.analysisDetails?.sharing?.allowExport;

  const isDashboardPage = getAnalysisPageType(location.pathname) === AnalysisPageType.Dashboard;

  const canViewTabs =
    !state.analysisDetails?.sharing ||
    (activeTab === AnalysisPageType.Overview && hasOverviewAccess) ||
    (activeTab === AnalysisPageType.Topics && hasTopicsAccess) ||
    (activeTab === AnalysisPageType.Comments && hasCommentsAccess) ||
    (activeTab === AnalysisPageType.Dashboard && hasDashboardAccess) ||
    activeTab === AnalysisPageType.Summary;

  const showPushWidgetChanges =
    isDashboardPage &&
    !sharingPreview &&
    state.widgetsApiData?.lastPushed &&
    ((isAnalysisOwner && !isViewer) || isAdmin || hasContributionPermission);

  const showManageWidgetsButton =
    isDashboardPage &&
    !sharingPreview &&
    ((isAnalysisOwner && !isViewer) || isAdmin || hasContributionPermission) &&
    state.widgetsApiData;

  // when navigating to the overview & dashboard tab, all filters should be reset on click
  const tabs = state.analysisModels
    ? [
        ((isAdmin && !sharingPreview) ||
          (isAnalysisOwner && !sharingPreview) ||
          hasSummaryAccess) &&
          settingsState.features.llm && {
            id: AnalysisPageType.Summary,
            label: getResource("analysis.tab.summary"),
            href: routes.summaryPage(
              state.analysisDetails.id,
              getUrlQueryString({ sharingPreview, step, expandedRowId, sharedWithPage, sharingId })
            ),
            onClick: () => analysisContextDispatch(resetAllFilters()),
          },
        ((isAdmin && !sharingPreview) ||
          (isAnalysisOwner && !sharingPreview) ||
          hasOverviewAccess) && {
          id: AnalysisPageType.Overview,
          label: getResource("analysis.tab.overview"),
          href: routes.overviewPage(
            state.analysisDetails.id,
            getUrlQueryString({ sharingPreview, step, expandedRowId, sharedWithPage, sharingId })
          ),
          onClick: () => analysisContextDispatch(resetAllFilters()),
        },
        ((isAdmin && !sharingPreview) ||
          (isAnalysisOwner && !sharingPreview) ||
          hasDashboardAccess) && {
          id: AnalysisPageType.Dashboard,
          label: getResource("analysis.tab.dashboard"),
          href: routes.dashboardPage(
            state.analysisDetails.id,
            getUrlQueryString({ sharingPreview, step, expandedRowId, sharedWithPage, sharingId })
          ),
          onClick: () => analysisContextDispatch(resetAllFilters()),
        },
        state.analysisDetails?.availableResults?.topics &&
          ((isAdmin && !sharingPreview) ||
            (isAnalysisOwner && !sharingPreview) ||
            hasTopicsAccess) && {
            id: AnalysisPageType.Topics,
            label: getResource("analysis.tab.topics"),
            href: routes.topicsPage(
              state.analysisDetails.id,
              getUrlQueryString({
                view:
                  location.pathname.includes("comments") &&
                  location.search.includes("Recommendations")
                    ? "recommendations"
                    : undefined,
                sharingPreview,
                step,
                expandedRowId,
                sharedWithPage,
                sharingId,
              })
            ),
          },
        ((isAdmin && !sharingPreview) ||
          (isAnalysisOwner && !sharingPreview) ||
          hasCommentsAccess) && {
          id: AnalysisPageType.Comments,
          label: getResource("analysis.tab.comments"),
          href: routes.commentsPage(
            state.analysisDetails.id,
            getUrlQueryString({
              sharingPreview,
              step,
              expandedRowId,
              sharedWithPage,
              sharingId,
              statistics: StatisticsType.total,
              view: CommentsView.allComments,
            })
          ),
        },
      ].filter(Boolean)
    : [];

  const handleDropdownChange = (selectedOption: LanguageCode) => {
    analysisContextDispatch(
      dispatch(
        setSelectedTranslationLanguage(
          translationOptions.find((option) => selectedOption === option.value)
        )
      )
    );
  };

  const getHeaderBackground = (): string => {
    if (!activeTab || getAnalysisPageType(location.pathname) !== activeTab) return "transparent";
    return [AnalysisPageType.Topics, AnalysisPageType.Comments].some((x) => x === activeTab)
      ? Color.white
      : Color.neutral10;
  };

  const handlePushWidgetChangesToSharees = async () => {
    try {
      await pushWidgetChangesToSharees(state.analysisDetails.id);
      handlers.updateLastWidgetsPushedDate();
      dispatch(showToastSuccess("toast.dashboardPushSuccessful"));
    } catch (err) {
      console.error(err);
      dispatch(showToastError("toast.dashboardPushError"));
    }
  };

  const getSelectedModelName = (): string =>
    state.selectedModelId &&
    state.selectedModelId === state.analysisDetails.selectedModel.customModelId
      ? state.analysisDetails.selectedModel.customModelName
      : getResource(`categorizationType.${state.analysisDetails.selectedModel.family}.name`);

  useEffect(() => {
    if (
      !activeTab ||
      getAnalysisPageType(location.pathname) !== activeTab ||
      [AnalysisPageType.Dashboard, AnalysisPageType.Overview].some((x) => x === activeTab)
    ) {
      analysisContextDispatch(showWhiteBackground(false));
      return;
    }
    analysisContextDispatch(showWhiteBackground(true));
    return;
  }, [activeTab, analysisContextDispatch, location.pathname]);

  useEffect(() => {
    if (!state.loadingAnalysisDetails) {
      setActiveTab(getAnalysisPageType(getSlug(location.pathname)));
    }
  }, [location, state.loadingAnalysisDetails]);

  useEffect(() => {
    if (!state.loadingAnalysisDetails) {
      analysisContextDispatch(
        setSelectedTranslationLanguage(
          translationOptions.find((op) => op?.value === currentUser.preferredLanguage) ||
            translationOptions[0]
        )
      );
    }
  }, [state.loadingAnalysisDetails]); // eslint-disable-line

  const handleHoverModelName = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowModelNameTooltip(true);
  };

  if (state.loadingAnalysisDetails && !state.analysisDetails.name) {
    return <LoadingDotsScreen />;
  }

  if (state.analysisDetails.status === AnalysisStatus.InProgress) {
    return <AnalysisJobProcessingModal />;
  }

  if (state.analysisDetails.uploadStatus === FileUploadStatus.Uploading) {
    return <FileUploadingModal isOpen={true} />;
  }

  if (state.analysisError) {
    return (
      <ErrorScreen
        errorType={
          sharingPreview && state.analysisError === PageErrorType.NoUsersSelected
            ? PageErrorType.NoUsersSelected
            : state.analysisError
        }
      />
    );
  }

  return (
    <StyledAnalysisPage isSharingPreview={!!sharingPreview}>
      <StyledAnalysisHeader
        backgroundColor={getHeaderBackground()}
        showTransition={getAnalysisPageType(location.pathname) === activeTab}
        isSharingPreview={!!sharingPreview}
      >
        {sharingPreview && <SharingPreviewHeader />}
        <Notch />
        <StyledTabsSectionContainer>
          {canViewTabs && <Tabs tabs={tabs} activeTab={activeTab} />}
          {getAnalysisPageType(location.pathname) === AnalysisPageType.Comments &&
            translationOptions.length > 1 && (
              <StyledTranslationDropdown>
                <Text resource={"comments.multilingual.dropdown.label"} />
                <Select
                  selectedOption={translationOptions.find(
                    (option) => option.value === state.selectedTranslationLanguage
                  )}
                  options={translationOptions}
                  handleChange={(selectedOption) => handleDropdownChange(selectedOption)}
                  buttonStyle={{
                    width: "152px",
                    padding: "8px, 10px, 8px, 10px",
                    fontWeight: "normal",
                    fontSize: "15px",
                    gap: "10px",
                  }}
                  dropdownWidth="152px"
                />
              </StyledTranslationDropdown>
            )}
          {getAnalysisPageType(location.pathname) === AnalysisPageType.Comments &&
            ((!sharingPreview && (isAdmin || isAnalysisOwner)) || allowExport) && <ExportButton />}
          {getAnalysisPageType(location.pathname) === AnalysisPageType.Overview && (
            <StyledSettingsAndModelContainer>
              {state.analysisDetails.selectedModel && (
                <StyledCurrentlySelectedModelContainer>
                  <CategorizationTypeSymbol
                    categorizationType={
                      state.analysisDetails.selectedModel.family as CategorizationType
                    }
                  />
                  <span
                    data-tooltip-id="selected-model-tooltip"
                    onMouseOver={handleHoverModelName}
                    onMouseOut={() => setShowModelNameTooltip(false)}
                  >
                    {getSelectedModelName()}
                  </span>
                  {showModelNameTooltip && (
                    <Tooltip tooltipId="selected-model-tooltip" isOpen>
                      {getSelectedModelName()}
                    </Tooltip>
                  )}
                </StyledCurrentlySelectedModelContainer>
              )}
              <AnalysisSettingsButton />
            </StyledSettingsAndModelContainer>
          )}
          {showPushWidgetChanges && (
            <StyledSharingChangesContainer>
              <Text
                resource={{
                  key: "sharing.lastPushed",
                  args: getLastWidgetPushDateArgs(
                    getLocalTime(state.widgetsApiData.lastPushed),
                    currentUser.preferredLanguage
                  ),
                }}
              />
              {state.widgetsApiData.pushAvailable && (
                <Button
                  variant={ButtonVariant.outlineBlue}
                  size={ButtonSize.sm}
                  style={{ marginLeft: "4px" }}
                  onClick={handlePushWidgetChangesToSharees}
                >
                  <Text resource="button.pushChanges" />
                </Button>
              )}
            </StyledSharingChangesContainer>
          )}
          {showManageWidgetsButton && (
            <Button
              variant={ButtonVariant.outlineBlue}
              size={ButtonSize.sm}
              onClick={() => dispatch(setShowManageWidgetsModal(true))}
              style={{ marginLeft: "4px" }}
            >
              <Icon
                type={IconType.gear}
                size={12}
                color={Color.blue50}
                style={{ marginRight: 8 }}
              />
              <Text resource="button.manageWidgets" />
            </Button>
          )}
        </StyledTabsSectionContainer>
      </StyledAnalysisHeader>
      {sharingPreview && state.loadingPreviewUsers ? null : <div>{children}</div>}
    </StyledAnalysisPage>
  );
};

export const AnalysisLayout = ({ children }) => {
  const location = useLocation();

  return (
    <AnalysisContextProvider>
      <ConditionalWrapper
        condition={!!getAnalysisPageType(getSlug(location.pathname))}
        wrapper={(children) => <AnalysisPage>{children}</AnalysisPage>}
      >
        <>
          {
            !location.pathname.includes("/settings") && <FileUploadingModal /> // @todo: flow tbd for R2.1
          }
          {children}
        </>
      </ConditionalWrapper>
    </AnalysisContextProvider>
  );
};

const StyledAnalysisPage = styled.div<{ isSharingPreview: boolean }>`
  padding-bottom: 60px;
`;

const StyledTabsSectionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 40px;
  justify-content: space-between;
  height: 100px;
  padding-bottom: 20px;
`;

const StyledAnalysisHeader = styled.div<{
  backgroundColor: string;
  showTransition: boolean;
  isSharingPreview: boolean;
}>`
  position: sticky;
  top: ${({ isSharingPreview }) => (isSharingPreview ? SHARING_PREVIEW_HEADER_HEIGHT : 0)}px;
  z-index: ${ZIndexStackingContext.medium + 1};
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: ${({ showTransition }) =>
    showTransition && "background-color 100ms ease-in-out 100ms"};

  /* Values below represent original dashboard header width (1175, 918) and now extended widget group width (1220, 972) */
  padding: 0 calc((1220px - 1175px) / 2);
  width: 1220px;
  margin-left: calc((1175px - 1220px) / 2);

  ${({ isSharingPreview }) => isSharingPreview && "margin-bottom: 42px;"}

  @media (max-width: 1366px) {
    width: 972px;
    margin-left: calc((918px - 972px) / 2);
  }
`;

const StyledSharingChangesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  mly-text {
    display: flex;
    align-items: center;
    gap: 4px;

    div {
      position: relative;

      span {
        position: absolute;
        font-size: 8px;
      }
    }
  }

  @media (min-width: 1366px) {
    margin-right: 6px;
  }
`;

const StyledSettingsAndModelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledCurrentlySelectedModelContainer = styled.div`
  border: 1px solid ${Color.indigo20};
  border-radius: 2px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${Color.indigo10};

  span {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const StyledTranslationDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 12px;
`;
