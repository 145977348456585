import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchSummaryOverview } from "./thunks";
import { SummaryType } from "ts/summary";
import { AxiosError } from "axios";
import { handleAxiosError } from "context/AnalysisContext/actions";
import { PageErrorType } from "ts/enums/pageErrorType";

type SummaryState = {
  summaryOverview: SummaryType;
  isLoading: boolean;
  isLoadingAnimationActive: boolean;
  isSummaryAnimationComplete: boolean;
  isCachedResponse: boolean;
  errorType: PageErrorType;
};

const initialState: SummaryState = {
  summaryOverview: null,
  isLoading: true,
  isLoadingAnimationActive: true,
  isSummaryAnimationComplete: false,
  isCachedResponse: false,
  errorType: null,
};

const SummarySlice = createSlice({
  name: "SummarySlice",
  initialState,
  reducers: {
    setIsLoadingAnimationActive: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAnimationActive = action.payload;
    },
    setIsSummaryAnimationComplete: (state, action: PayloadAction<boolean>) => {
      state.isSummaryAnimationComplete = action.payload;
    },
    setIsCachedResponse: (state, action: PayloadAction<boolean>) => {
      state.isCachedResponse = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSummaryOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSummaryOverview.fulfilled, (state, action) => {
      state.summaryOverview = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchSummaryOverview.rejected, (state, rejectValue) => {
      state.errorType = handleAxiosError(rejectValue.payload as AxiosError<any>).payload;
      state.isLoading = false;
    });
  },
});

export const {
  setIsLoadingAnimationActive,
  clearState,
  setIsSummaryAnimationComplete,
  setIsCachedResponse,
} = SummarySlice.actions;

export default SummarySlice.reducer;
